<template>
  <container class="header">
    <div class="header__skeleton skeleton" v-if="! $store.getters['user/isProfileDownloaded']">
      <div class="skeleton__avatar skeleton-element"></div>
      <div class="skeleton__block skeleton-element"></div>
      <div class="skeleton__block skeleton__block_768 skeleton-element"></div>
    </div>
    <div class="header__wrapper" v-if="$store.getters['user/isProfileDownloaded']">
      <div class="header__exit" @click="exit"></div>
      <div class="header__main">
        <div class="header__avatar-container">
          <img class="header__avatar" :src="avatar" alt="Аватар">
        </div>
        <div class="header__user">
          <span class="header__role">
            {{ $store.state.user.profile.role.name }}
          </span>
          <span class="header__login">
            {{ $store.state.user.profile.login }}
          </span>
          <span class="header__project" v-if="projectName">
            {{ projectName }}
          </span>
        </div>
        <div class="header__user-info">
          <span class="header__name">
            {{ `${$store.state.user.profile.last_name} ${$store.state.user.profile.first_name} ${patronymic}` }}
          </span>
          <span class="header__phone" v-if="$store.state.user.profile.extra.phone">
            {{ $store.state.user.profile.extra.phone }}
          </span>
          <span class="header__email" v-if="$store.state.user.profile.email">
            {{ $store.state.user.profile.email }}
          </span>
        </div>
      </div>
    </div>
    <div class="header__nav">
      <navigation/>
    </div>
  </container>
</template>

<script>
import Container from '@/layouts/Container'
import Navigation from '@/components/Navigation'

export default {
  name: 'AppHeader',
  components: {
    Container,
    Navigation
  },
  methods: {
    async exit() {
      await this.$store.dispatch('user/signOut')

      this.$router.push({ name: 'Login' })
    }
  },
  computed: {
    patronymic() {
      return this.$store.state.user.profile.patronymic || ''
    },

    avatar() {
      return this.$store.state.user.profile.avatar || require('@/assets/svg/avatar.svg')
    },

    projectName() {
      const profile = this.$store.state.user.profile

      return Object.prototype.hasOwnProperty.call(profile, 'project') ? profile.project.name : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.header {
  margin-bottom: 14px;
}

.header__wrapper {
  position: relative;
}

.header__exit {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 16px;
  height: 16px;
  background-image: url("../assets/svg/exit.svg");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.header__main {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $separator-color;
}

.header__user {
  flex-grow: 1;
}

.header__avatar-container, .header__user {
  border-bottom: 1px solid $separator-color;
}

.header__avatar-container {
  flex-basis: 60px;
  padding: 20px 0 20px 20px;
}

.header__avatar {
  width: 60px;
  border-radius: $border-radius;
}

.header__user {
  padding: 20px 15px;
}

.header__user, .header__user-info {
  display: flex;
  flex-direction: column;
}

.header__role, .header__login, .header__project {
  color: $secondary-text-color;
}

.header__role, .header__name, .header__phone, .header__email {
  font-size: 15px;
}

.header__role {
  margin-bottom: 10px;
  font-family: "Inter Medium", "sans-serif";
  font-weight: 500;
}

.header__login, .header__project {
  font-size: 14px;
}

.header__login {
  margin-bottom: 10px;
}

.header__user-info {
  flex-basis: 100%;
  padding: 20px;
}

.header__name, .header__phone {
  margin-bottom: 12px;
}

.header__nav {
  padding: 15px 20px 0;
}

/* skeleton */
.skeleton {
  display: flex;
  padding: 20px;
  gap: 20px;
  border-bottom: 1px solid $separator-color;
}

.skeleton__avatar, .skeleton__block {
  flex-basis: 60px;
  height: 60px;
}

.skeleton__block {
  flex-grow: 1;
}

.skeleton__block_768 {
  display: none;
}

@media screen and (min-width: 576px) {
  .header {
    margin-bottom: 28px;
  }

  .header__exit {
    width: 18px;
    height: 18px;
  }

  .header__avatar-container, .header__user-info {
    padding-bottom: 25px;
    border: none;
  }

  .header__user {
    flex-grow: 0;
    flex-basis: 100%;
    order: 3;
    padding: 0 20px 25px;
    border: none;
  }

  .header__user-info {
    flex-grow: 1;
    flex-basis: auto;
    order: 2;
  }

  .header__avatar {
    width: 100px;
  }

  .header__role, .header__name, .header__phone, .header__email {
    font-size: 17px;
  }

  .header__role, .header__login {
    margin-bottom: 12px;
  }

  .header__login, .header__project {
    font-size: 16px;
  }

  .header__name, .header__phone {
    margin-bottom: 14px;
  }

  // skeleton
  .skeleton {
    padding: 30px 20px;
  }

  .skeleton__avatar, .skeleton__block {
    flex-basis: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 768px) {
  .header__exit {
    top: 30px;
  }

  .header__main {
    padding: 30px 20px;
  }

  .header__avatar-container, .header__user-info {
    flex-grow: 0;
    padding: 0;
  }

  .header__avatar-container {
    margin-right: 30px;
  }

  .header__user {
    flex-basis: auto;
    flex-grow: 1;
    padding: 0;
    margin-right: 38px;
    text-align: right;
  }

  .header__nav {
    padding: 25px 30px 0;
  }

  // skeleton
  .skeleton {
    padding: 30px 20px;
  }

  .skeleton__block_768 {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .header__exit {
    right: 30px;
  }

  .header__main {
    padding: 30px;
  }

  // skeleton
  .skeleton {
    padding: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .header__exit {
    right: 40px;
  }

  .header__main {
    padding: 30px 40px;
  }

  .header__nav {
    padding: 25px 40px 0;
  }

  // skeleton
  .skeleton {
    padding: 30px 40px;
  }
}
</style>