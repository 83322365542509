<template>
  <main class="wrapper">
    <slot/>
  </main>
</template>

<script>
export default {
  name: 'Wrapper'
}
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 14px 0;
}

@media screen and (min-width: 576px) {
  .wrapper {
    padding: 28px 0;
  }
}
</style>