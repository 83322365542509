const camelToSnake = (string) => {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

const scheduleStatusColor = (status) => {
  const colorMap = {
    'work': 'green',
    'day_off': 'blue',
    'sick': 'orange',
    'vacation': 'yellow',
    'repair': 'purple',
    'express': 'red'
  }

  for (const [key, value] of Object.entries(colorMap)) {
    if (status === key) {
      return value
    }
  }

  return 'teal'
}

const parseDateToClassicString = (date) => {
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let monthDay = date.getDate()

  if (monthDay < 10) {
    monthDay = '0' + monthDay;
  }

  if (month < 10) {
    month = '0' + month
  }

  return `${monthDay}.${month}.${year}`
}

const parseDateToIsoString = (date) => {
  const parsed = date.split('.')

  return `${parsed[2]}-${parsed[1]}-${parsed[0]}`
}

const getDays = (year, month) => new Date(year, month, 0).getDate()

const getNameOfDay = (year, month, day) => {
  switch (new Date(year, month, day).getDay()) {
    case 0:
      return 'ВС'

    case 1:
      return 'ПН'

    case 2:
      return 'ВТ'

    case 3:
      return 'СР'

    case 4:
      return 'ЧТ'

    case 5:
      return 'ПТ'

    case 6:
      return 'СБ'

    default:
      return '-'
  }
}

export  { camelToSnake, scheduleStatusColor, parseDateToClassicString, parseDateToIsoString, getDays, getNameOfDay }