<template>
  <button :class="['button', buttonStyle]" :type="type">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    buttonStyle: {
      type: String,
      default: 'button_primary'
    },
    isSubmit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type() {
      return this.$props.isSubmit === true ? 'submit' : 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.button {
  appearance: none;
  height: 50px;
  padding: 0;
  line-height: 100%;
  font-family: "Inter Regular", "sans-serif";
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  border-radius: $border-radius;
  cursor: pointer;
}

.button_primary {
  color: #fff;
  background-color: #2a486a;
  border: none;

  &:hover {
    background-color: #072547;
  }
}

.button_action {
  color: #2A486A;
  background-color: #fff;
  border: 1px solid #2a486a;

  &:hover {
    color: #fff;
    background-color: #2a486a;
  }
}

.button_cancel {
  color: #ce1548;
  background-color: #fff;
  border: 1px solid #ce1548;

  &:hover {
    color: #fff;
    background-color: #ce1548;
  }
}

@media screen and (min-width: 576px) {
  .button {
    height: 55px;
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) {
  .button {
    height: 68px;
    font-size: 16px;
  }
}
</style>