<template>
  <nav class="nav">
    <div class="nav__skeleton skeleton" v-if="! $store.getters['user/isProfileDownloaded']">
      <div class="skeleton__wrapper">
        <div class="skeleton__element-container skeleton__element-container_left">
          <div class="skeleton__link skeleton-element"></div>
          <div class="skeleton__link skeleton-element"></div>
        </div>
        <div class="skeleton__element-container skeleton__element-container_right">
          <div class="skeleton__link skeleton-element"></div>
        </div>
      </div>
    </div>
    <ul class="nav__list" v-if="$store.getters['user/isProfileDownloaded']">
      <li
          class="nav__item nav__page-link"
          :class="{ 'nav__page-link_active' : isActive('projects') }"
          v-if="hasAccess(['super_admin'])"
      >
        <router-link :to="{ name: 'Projects' }">
          Проекты
        </router-link>
      </li>
      <li
          class="nav__item nav__page-link"
          :class="{ 'nav__page-link_active' : isActive('users') }"
          v-if="hasAccess(['super_admin'])"
      >
        <router-link :to="{ name: 'Users' }">
          Пользователи
        </router-link>
      </li>
      <li
          class="nav__item nav__page-link"
          :class="{ 'nav__page-link_active' : isActive('projects') }"
          v-if="hasAccess(['project_admin']) && $store.state.user.profile.project_id"
      >
        <router-link :to="
            {
              name: 'EditProject',
              params: {
                id: $store.state.user.profile.project_id
              }
            }
        ">
          Пользователи
        </router-link>
      </li>
      <li
          class="nav__item nav__page-link"
          :class="{ 'nav__page-link_active' : isActive('schedule') }"
          v-if="hasAccess(['driver', 'collector'])"
      >
        <router-link :to="{ name: 'Schedule' }">
          График работы
        </router-link>
      </li>
      <li
          class="nav__item nav__page-link"
          :class="{ 'nav__page-link_active' : isActive('profile') }"
      >
        <router-link :to="{ name: 'Profile' }">
          Профиль
        </router-link>
      </li>
      <li
          class="nav__item nav__item_320 nav__application-link"
          v-if="hasAccess(['project_admin']) && requests"
      >
        <router-link :to="
            {
              name: 'EditProject',
              params: {
                id: $store.state.user.profile.project_id
              },
              query: {
                page: 1,
                per_page: 10,
                has_requests: true
              }
            }
        ">
          <img class="nav__link-icon" src="../assets/svg/applications.svg" alt="Заявки">
        </router-link>
      </li>
      <li
          class="nav__item nav__item_576 nav__application-link"
          v-if="hasAccess(['project_admin']) && requests"
      >
        <router-link :to="
            {
              name: 'EditProject',
              params: {
                id: $store.state.user.profile.project_id
              },
              query: {
                page: 1,
                per_page: 10,
                has_requests: true
              }
            }
        ">
          {{ requests }} новых заявок
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  methods: {
    isActive(routeGroup) {
      return this.$route.meta.group === routeGroup
    },

    hasAccess(roles) {
      return roles.indexOf(this.$store.state.user.profile.role.slug) !== -1
    }
  },
  computed: {
    requests() {
      const profile = this.$store.state.user.profile

      return Object.prototype.hasOwnProperty.call(profile, 'project') ? profile.project.requests_quantity : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.nav {
  padding-bottom: 20px;
}

.nav__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav__item:not(:last-child) {
  margin-right: 15px;
}

.nav__page-link {
  font-size: 14px;
  color: $primary-links-color-inactive;

  &_active {
    position: relative;
    color: $primary-links-color;

    &::after {
      content: "";
      position: absolute;
      bottom: -21px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $primary-links-color;
    }
  }
}

.nav__application-link {
  flex-grow: 1;
  text-align: right;
  color: #2b7c1c;
}

.nav__item_576 {
  display: none;
}

.nav__link-icon {
  width: 18px;
}

/* skeleton */
.skeleton__wrapper, .skeleton__element-container {
  display: flex;
}

.skeleton__element-container {
  flex-basis: 50%;
}

.skeleton__element-container_right {
  justify-content: right;
}

.skeleton__link {
  display: inline-block;
  width: 71px;
  height: 24px;

  &:not(:last-child) {
    margin-right: 15px;
  }
}

@media screen and (min-width: 576px) {
  .nav__item:not(:last-child) {
    margin-right: 30px;
  }

  .nav__page-link {
    font-size: 16px;
  }

  .nav__item_320 {
    display: none;
  }

  .nav__item_576 {
    display: list-item;
  }

  /* skeleton */
  .skeleton__link:not(:last-child) {
    margin-right: 30px;
  }
}

@media screen and (min-width: 768px) {
  .nav {
    padding-bottom: 30px;
  }

  .nav__page-link_active::after {
    bottom: -31px;
  }

  /* skeleton */
  .skeleton__link {
    width: 111px;
  }
}

@media screen and (min-width: 1200px) {
  .nav__item:not(:last-child) {
    margin-right: 40px;
  }

  /* skeleton */
  .skeleton__link:not(:last-child) {
    margin-right: 40px;
  }
}
</style>