<template>
  <div class="input">
    <label class="input__label" :for="id">{{ label }}</label>
    <input class="input__area" :class="inputClassList"
           v-maska :data-maska="mask"
           :type="type" v-model="value" :id="id" :readonly="readonly"
           @input="update" @focus="focus"
    >
    <span class="input__close-icon" v-show="enableErase && modelValue" @click="erase(id)"></span>
  </div>
</template>

<script>
import { vMaska } from 'maska'

export default {
  name: 'AppInput',
  props: {
    modelValue: [String, Number],
    inputClassList: Array,
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ''
    },
    enableErase: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    maska: vMaska
  },
  emits: ['update:modelValue', 'erase', 'focus'],
  data() {
    return {
      value: ''
    }
  },
  methods: {
    update(event) {
      this.$emit('update:modelValue', event.target.value)
    },

    erase(id) {
      this.value = ''
      this.$emit('erase', id)
    },

    focus(event) {
      this.$emit('focus', event)
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue !== oldValue && this.value !== newValue) {
        this.value = newValue
      }
    }
  },
  created() {
    this.value = this.$props.modelValue
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.input {
  display: inline-block;
  position: relative;
}

.input__label {
  position: absolute;
  top: -6px;
  left: 20px;
  padding: 0 10px;
  font-size: 10px;
  color: $label-text-color;
  background-color: #fff;
}

.input__area {
  width: 100%;
  height: 50px;
  padding: 0 40px 0 20px;
  font-size: 13px;
  border: 1px solid $separator-color;
  border-radius: $border-radius;

  &:focus {
    outline: none;
  }
}

.input__close-icon {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  transform: translateY(100%);

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border: 0.5px solid $separator-color;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@media screen and (min-width: 576px) {
  .input__label {
    font-size: 12px;
  }

  .input__area {
    height: 55px;
    font-size: 15px;
  }

  .input__close-icon {
    top: 2px;
    width: 17px;
    height: 17px;
  }
}

@media screen and (min-width: 768px) {
  .input__area {
    height: 68px;
  }

  .input__close-icon {
    top: 8px;
    width: 18px;
    height: 18px;
  }
}

@media screen and (min-width: 992px) {
  .input__label {
    font-size: 13px;
  }

  .input__area {
    font-size: 16px;
  }
}
</style>