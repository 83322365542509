<template>
  <section class="container">
    <slot/>
  </section>
</template>

<script>
export default {
  name: 'LayoutContainer'
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.container {
  background-color: $container-background;
  border: 1px solid $border-color;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.03);
}

@media screen and (min-width: 1200px) {
  .container {
    border-radius: $border-radius;
  }
}
</style>